<template>
  <div class="active-alerts">
    <h4>Alertas activas</h4>
    <h5 v-if="alerts.length == 0">Enhorabuena no hay alertas activas</h5>
    <AlertCard
      v-for="alert, index in alerts"
      :key="index"
      :alert="alert"
      :index="index"
      :openMapModal="openMapModal"
      :goToAlertDetails="goToAlertDetails"
      :showStatus="true"
    />

    <!-- Modal -->
    <div v-if="alerts.length > 0" class="modal fade" id="mapModal" tabindex="-1" role="dialog" aria-labelledby="mapModalTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="mapModalTitle">Ubicación en tiempo real</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <GmapMap
              :center="{
                lat: alerts[map.alertIndex].latitude,
                lng: alerts[map.alertIndex].longitude
              }"
              :zoom="13"
              :options="map.options"
              map-type-id="roadmap"
              style="width: 100%; height: 400px"
            >
              <GmapMarker
                :position="{
                  lat: alerts[map.alertIndex].latitude,
                  lng: alerts[map.alertIndex].longitude
                }"
                :clickable="false" :draggable="false"
              ></GmapMarker>
            </GmapMap>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            <button type="button" class="btn btn-primary" v-bind:disabled="alerts[map.alertIndex].attendedBy != null"  @click="goToAlertDetails(alerts[map.alertIndex])">Atender alerta</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ActiveAlerts',
  data() {
    return {
      map: {
        alertIndex: 0,
        options: this.$global.mapOptions,
      },
      alerts: []
    }
  },
  sockets: {
    alertRecived(data) {
      this.getAlerts()
    },
    locationUpdated(data) {
      const id = data[0]
      const latitude = data[1]
      const longitude = data[2]
      // Si las alertas enstán vacias obtener alertas
      if (this.alerts.length == 0) {
        this.getAlerts()
      } else {
        this.alerts.forEach(alert => {
          if (alert._id == id) {
            alert.latitude = latitude
            alert.longitude = longitude
          }
        })
      }
      this.reorderObjectToDist(this.alerts);
    },
    alertStopped(id) {
      this.dropAlert(id)
    },
    alertAttended(data) {
      const id = data[0]
      const adminId = data[1]
      if (this.alerts.length > 0) {
        this.alerts.forEach(alert => {
          if (alert._id == id) {
            alert.attendedBy = adminId
          }
        })
      } else {
        this.getAlerts()
      }
    }
  },
  methods: {
    getDist(latitude, longitude) {
      let dist = this.$getDist(
        latitude,
        longitude,
        localStorage.getItem('latitude'),
        localStorage.getItem('longitude')
      );
      return dist;
    },
    reorderObjectToDist(obj) {
      return obj.sort((a, b) => {
        return this.$getDist(
          a.latitude,
          a.longitude,
          localStorage.getItem('latitude'),
          localStorage.getItem('longitude')
        ) - this.$getDist(
          b.latitude,
          b.longitude,
          localStorage.getItem('latitude'),
          localStorage.getItem('longitude')
        )
      })
    },
    getAlerts () {
      this.axios.get('/api/alerts/active',
        {
          headers: {
            'Authorization': this.$getAuthorization(),
          },
        })
        .then(response => {
          if (response.data.status == 'success') {
            let alerts = this.reorderObjectToDist(response.data.alerts)
            this.alerts = alerts;
          } else {
            console.log(response.data.message, response.data.error)
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    dropAlert(id) {
      // obtener el index de la alerta que tiene el mismo id
      const index = this.alerts.findIndex(alert => alert._id == id)
      if (index == this.map.alertIndex) {
        $('#mapModal').modal('hide')
      }
      // Eliminar la alerta de las alerts
      this.alerts = this.alerts.filter(alert => alert._id != id)
    },
    openMapModal(index) {
      this.map.alertIndex = index;
      $('#mapModal').modal('show');
    },
    goToAlertDetails(alert) {
      $('#mapModal').modal('hide');
      this.$router.push({
        name: 'alert',
        params: {
          id: alert._id
        }
      })
    }
  },
  created() {
    this.getAlerts()
  },
}
</script>