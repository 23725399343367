<template>
  <div class="home">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-3 col-md-4 col-sm-5 col-xs-12">
          <ListButtons
            class="mt-3 mb-3"
            :items="sidebar"
            :checked="contentVisible"
            @click="onSidebarClick"
          />
        </div>
        <div class="col-lg-9 col-md-8 col-sm-7 col-xs-12">
          <div class="container">
            <div class="mt-3 mb-3">
              <ActiveAlerts
                v-if="contentVisible === 0"/>
              <CompletedAlerts
                v-if="contentVisible === 1"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActiveAlerts from '@/components/views/home/ActiveAlerts.vue'
import CompletedAlerts from '@/components/views/home/CompletedAlerts.vue'

export default {
  name: 'Home',
  data() {
    return {
      sidebar: [
        { name: 'Alertas activas' },
        { name: 'Alertas completadas' },
      ],
      contentVisible: parseInt(this.$route.query.opt) || 0,
    }
  },
  components: {
    ActiveAlerts,
    CompletedAlerts,
  },
  methods: {
    onSidebarClick(index) {
      this.$router.replace({
        name: 'home',
        query: {
          opt: index,
        },
      });
      this.contentVisible = index;
    },
  },
}
</script>
