<template>
  <div class="complete-alerts">
    <h4>Alertas completadas</h4>
    <h5 v-if="alerts.length == 0">No hay alertas completadas</h5>
    <AlertCard
      v-for="alert, index in alerts"
      :key="index"
      :alert="alert"
      :index="index"
      :openMapModal="openMapModal"
      :goToAlertDetails="goToAlertDetails"
      textGoToAlertDetails="Ver detalles"
    />

    <!-- Modal -->
    <div v-if="alerts.length > 0" class="modal fade" id="mapModal" tabindex="-1" role="dialog" aria-labelledby="mapModalTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="mapModalTitle">Ubicación en tiempo real</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <GmapMap
              :center="{
                lat: alerts[map.alertIndex].latitude,
                lng: alerts[map.alertIndex].longitude
              }"
              :zoom="13"
              :options="map.options"
              map-type-id="roadmap"
              style="width: 100%; height: 400px"
            >
              <GmapMarker
                :position="{
                  lat: alerts[map.alertIndex].latitude,
                  lng: alerts[map.alertIndex].longitude
                }"
                :clickable="false" :draggable="false"
              ></GmapMarker>
            </GmapMap>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            <button type="button" class="btn btn-primary" v-bind:disabled="alerts[map.alertIndex].attendedBy != null"  @click="goToAlertDetails(alerts[map.alertIndex])">Atender alerta</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompletedAlerts',
  data() {
    return {
      map: {
        alertIndex: 0,
        options: this.$global.mapOptions,
      },
      alerts: []
    }
  },
  methods: {
    getAlerts () {
      this.axios.get('/api/alerts/completed',
        {
          headers: {
            'Authorization': this.$getAuthorization(),
          },
        })
        .then(response => {
          this.alerts = response.data.alerts;
        })
        .catch(error => {
          console.log(error);
        });
    },
    openMapModal(index) {
      this.map.alertIndex = index;
      $('#mapModal').modal('show');
    },
    goToAlertDetails(alert) {
      $('#mapModal').modal('hide');
      this.$router.push({
        name: 'alert',
        params: {
          id: alert._id
        }
      })
    }
  },
  created() {
    this.getAlerts()
  },
}
</script>